import './api.css'

function Api() {
    return (
        <div className='api'>
            <div className="description">
                <h2>Public API Documentation</h2>
                <p>
                    Welcome to the documentation of our Cryptocurrency Predictions API, where we provide real-time access to price predictions for BTCUSDT and ETHUSDT pairs across various timeframes. This API is public and can be used by any developer interested in receiving prediction updates to integrate into their applications or services.
                </p>
                <h4>Connecting to the WebSocket</h4>
                <p>To start receiving predictions, connect your client to the WebSocket at our URL:</p>
            </div>
            <div className="code-card">
                wss://predictable-api.tech
            </div>
            <div className='description'>
                <p>
                    Once connected, you will receive real-time updates in the format specified below.
                </p>
            
                <h4>Data format</h4>
                <p>The data is emitted in a two-dimensional vector named results, where: </p>
                <ul>
                    <li>results[0]: Contains data for the BTCUSDT pair.</li>
                    <li>results[1]: Contains data for the ETHUSDT pair.</li>
                </ul>
                <p>Each element of results[i] contains information for different timeframes:</p>
                <ul>
                    <li>results[i][0]: Data for "1d" (1 day).</li>
                    <li>results[i][1]: Data for "4h" (4 hours).</li>
                    <li>results[i][2]: Data for "1h" (1 hour).</li>
                    <li>results[i][3]: Data for "15m" (15 minutes).</li>
                    <li>results[i][4]: Data for "5m" (5 minutes).</li>
                    <li>results[i][5]: Data for "1m" (1 minute).</li>
                </ul>
                <h4>Data structure</h4>
                <p>Each results[i][j] is a dictionary containing:</p>
                <ul>
                    <li>history: An array of historical data.</li>
                    <li>prediction: An array of the prediction, which is the main focus of this API.</li>
                </ul>
                <p>Example of Received Message</p>
            </div>
            <div className="code-card">
                <pre>
                    <code>
                        {`[
    [
      {
        "history": [/* historical data for BTCUSDT 1d */],
        "prediction": [/* prediction for BTCUSDT 1d */]
      },
      // More timeframes for BTCUSDT...
    ],
    [
      {
        "history": [/* historical data for ETHUSDT 1d */],
        "prediction": [/* prediction for ETHUSDT 1d */]
      },
      // More timeframes for ETHUSDT...
    ]
]`}
                    </code>
                </pre>
            </div>
            <h4>Client Usage</h4>
            <p>
                To use these data in your client, here is an example of how you might process the received data in JavaScript:
            </p>
            <div className="code-card">
                <pre>
                    <code>
                        {`const io = require('socket.io-client');

// Create an instance of the Socket.IO client
const socket = io('wss://predictable-api.tech');

// Define the connect event
socket.on('connect', () => {
    console.log('Connected to the Predictable Public API');
});

// Define the message event for 'all_results'
socket.on('all_results', (data) => {
    const results = data;
    if (results && results.length > 0) {
        const btcusdtDailyPrediction = results[0][0]?.prediction;
        console.log('Daily prediction for BTCUSDT:', btcusdtDailyPrediction);
        // Process the rest of the data as needed here
    }
});

// Handle disconnection
socket.on('disconnect', () => {
    console.log('Disconnected from the server');
});
`}
                    </code>
                </pre>
            </div>
            <p>
                Here is an example of how you might process the received data in Python:
            </p>
            <div className="code-card">
                <pre>
                    <code>
                        {`import socketio

# Create an instance of the Socket.IO client
sio = socketio.Client()

# Define the connection event
@sio.event
def connect():
    print('Connected to the Predictable Public API')

# Define the message event
@sio.on('all_results')
def on_message(data):
    results = data
    if results:
        btcusdtDailyPrediction = results[0][0].get('prediction')
        print('Daily prediction for BTCUSDT:', btcusdtDailyPrediction)
        # Process the rest of the data as needed here

# Connect to the server
sio.connect('wss://predictable-api.tech')

# Keep the connection
sio.wait()
`}
                    </code>
                </pre>
            </div>

        </div>
    )
}

export default Api