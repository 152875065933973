import './research.css'


function Research() {
    return (
        <div className='project'>

            <div className='description'>
                <p>
                    At Predictable, we transform the complexity of cryptocurrency markets into clear and accessible predictions. Our platform provides real-time visualizations of the leading cryptocurrencies, employing a powerful artificial intelligence model to predict price movements across different time intervals. Designed for traders and investors alike, Predictable is more than an analytical tool; it's an indispensable companion for making informed decisions.
                </p>
                <p>
                    Each graph in Predictable is not just a tracking of price; it's the culmination of an exhaustive analysis of historical data, interpreted through our state-of-the-art AI model. With consistent and accurate updates, our app offers short- and long-term projections on various time scales, from minutes to hours, providing a comprehensive view of the market's possible direction.
                </p>
                <p>
                    Predictable's intuitive interface is crafted to maximize efficiency and clarity. Users can customize their viewing experience, selecting specific time intervals and comparing different cryptocurrencies with ease. With the confidence provided by AI predictions and the ability to visualize complex trends simply, Predictable positions itself as the preferred choice for cryptocurrency enthusiasts seeking an analytical edge in a dynamic market.
                </p>
                <p>
                    Developed with a focus on precision and usability, Predictable is the culmination of an unwavering commitment to innovation in cryptocurrency analysis. Experience the future of trading with Predictable's AI predictions, and step towards more informed and strategic decisions in the cryptocurrency market.
                </p>
            </div>

        </div>
    )
}

export default Research