import './graphics.css';
import React, { useEffect, useState, useCallback } from 'react';
import LineChart from './lineChart';
import LoadingChart from './loadingChart';
import { getSocket, disconnectSocket } from './socket';
import LegalDisclaimer from './legalDiscraimer';

function Graphics() {
    const [results, setResults] = useState([]);
    const [sortBy, setSortBy] = useState("interval")
    const [intervalSort, setIntervalSort] = useState(false)
    const [symbolSort, setSymbolSort] = useState(true)
    const [sortedResults, setSortedResults] = useState([])

    const sortResults = useCallback(() => {
        if (results.length === 0) {
            return [];
        }

        const sortedGraphics = []

        if (sortBy === 'interval') {
            for (let i = 0; i < results[0].length; i++) {
                for (let j = 0; j < results.length; j++) {
                    const intervalIndex = intervalSort ? i : results[0].length - 1 - i;
                    const symbolIndex = symbolSort ? j : results.length - 1 - j;
                    sortedGraphics.push(results[symbolIndex][intervalIndex])
                }
            }
        } else {
            for (let i = 0; i < results.length; i++) {
                for (let j = 0; j < results[0].length; j++) {
                    const intervalIndex = intervalSort ? j : results[0].length - 1 - j;
                    const symbolIndex = symbolSort ? i : results.length - 1 - i;
                    sortedGraphics.push(results[symbolIndex][intervalIndex])
                }
            }
        }

        return sortedGraphics
    }, [results, sortBy, intervalSort, symbolSort]);

    useEffect(() => {
        const socket = getSocket();

        socket.on('all_results', (data) => {
            setResults(data);

        });
        return () => {
            disconnectSocket(socket);
        };
    }, []);

    useEffect(() => {
        //console.log('ALL_RESULTS actualizados:', results);
        const sortedGraphics = sortResults();
        setSortedResults(sortedGraphics)
    }, [results, sortBy, intervalSort, symbolSort, sortResults]); // Este useEffect se dispara cuando 'results' cambia

    const sortBySelector = (selection) => {
        console.log(`Sorting by ${selection}`);
        if (sortBy !== selection) {
            setSortBy(selection);
        }
    }
    const swichSortInterval = () => {
        setIntervalSort(!intervalSort);

    }
    const swichSortSymbol = () => {
        setSymbolSort(!symbolSort);

    }

    return (
        <div >
            <div className="Description">
                AI predictions of main cryptocurrencies
                <p>
                    Price against Tether, different time intervals predictions from historical data. Powered by Vulpinex v1.20
                </p>
            </div>

            <div className='Graph-Menu'>
                {"Sort by:"}
                <button
                    onClick={() => sortBySelector("interval")}
                    className={`button ${sortBy === "interval" ? "selected" : ""}`}
                >
                    Interval
                </button>
                <button
                    className={`material-symbols-outlined notranslate ${intervalSort ? "" : "icon-flipped"}`}
                    onClick={() => swichSortInterval()}
                >
                    Filter_List
                </button>
                <div className="Separator"></div>
                <button
                    onClick={() => sortBySelector("symbol")}
                    className={`button ${sortBy === "symbol" ? "selected" : ""}`}
                >
                    Symbol
                </button>
                <button
                    className={`material-symbols-outlined notranslate ${symbolSort ? "" : "icon-flipped"}`}
                    onClick={() => swichSortSymbol()}

                >
                    Filter_List
                </button>

            </div>

            <div className='graphics-frame' >
                {sortedResults && sortedResults.map((result, index) => (
                    <div className="graphics" key={index}> {/* La key se mueve aquí */}
                        <LineChart results={result} />
                    </div>
                ))}
                {sortedResults.length === 0 &&
                    <div className="graphics">
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                        <LoadingChart></LoadingChart>
                    </div>

                }
            </div>
            <LegalDisclaimer></LegalDisclaimer>
        </div>


    );
}

export default Graphics;
