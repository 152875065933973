import React from 'react';
import './legalDiscraimer.css';

function LegalDisclaimer() {
    return (
        <div className='Legal-Discraimer'>
            The material on our website, along with links, applications,
            forums, blogs, social media accounts, and other related platforms,
            is intended to provide general information from external sources.
            We do not assume responsibility for the accuracy or timeliness of
            this information and do not guarantee its reliability.
            Our content should not be taken as financial, legal, or any kind of
            advice for specific purposes. The use of our information is at your own risk.
            We recommend conducting your own research and analysis to validate
            the information before relying on it.
            Commercial activities carry significant risks and can
            lead to substantial losses; therefore, consulting with a financial advisor
            before making any decision is advisable. Nothing on our website should be
            interpreted as an encouragement or proposal to undertake any action.
        </div>
    );
}

export default LegalDisclaimer;