import io from 'socket.io-client';

let socket;

export const getSocket = () => {
    if (!socket) {
        socket = io('https://predictable-api.tech');
        //socket = io('http://localhost:8080');


        socket.on('connect', () => {
            console.log('socket conectado', socket);
        });

        socket.on('disconnect', () => {
            console.log('socket desconectado');
        });

        socket.on('connect_error', (error) => {
            console.log('Error de conexión:', error);
        });
    } else {
        console.log('socket ya conectado');
    }

    return socket;
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
};
