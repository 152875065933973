import './model.css'

function Token () {
    return (
        <div className='model'>
           AI model information and paper, coming soon :)
        </div>
    )
}

export default Token