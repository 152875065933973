import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import './lineChart.css'
import html2canvas from 'html2canvas';
import { ReactComponent as AppLogo } from '../desings/predictable_logo_g.svg'
import PairIcons from './pairIcons';


const LineChart = ({ results }) => {
    const { history, prediction, extra, info } = results;
    const historyData = useMemo(() => history.map(candle => ({
        time: new Date(candle["time"]),
        close: candle["close"]
    })), [history]);
    
    const predictionData = useMemo(() => prediction.map(candle => ({
        time: new Date(candle["time"]),
        close: candle["close"]
    })), [prediction]);
    
    const extraData = useMemo(() => extra.map(candle => ({
        time: new Date(candle["time"]),
        close: candle["close"]
    })), [extra]);

    const ref = useRef();
    const [width, setWidth] = useState(0);
    const [finalWidth, setFinalWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const height_disp = 32

    useEffect(() => {
        const handleResize = () => {
            const parentWidth = document.querySelector('.panel-chart').clientWidth;
            const diffWidth = Math.min(1.0, parentWidth / 838)
            setWidth(parentWidth);
            setFinalWidth(diffWidth);

            const resultHeight = parentWidth > 600 ? 200 : 200
            setHeight(resultHeight)

        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [results]);

    useEffect(() => {
        const svg = d3.select(ref.current);
        svg.selectAll("*").remove(); // Limpia el svg antes de renderizarlo

        // Define el área de recorte
        svg.append("defs").append("svg:clipPath")
            .attr("id", "clipY")
            .append("svg:rect")
            .attr("width", width - 50)
            .attr("height", height - 4)
            .attr("x", 10)
            .attr("y", 2);

        svg.append("defs").append("svg:clipPath")
            .attr("id", "clipX")
            .append("svg:rect")
            .attr("width", width - 60)
            .attr("height", height)
            .attr("x", 10)
            .attr("y", 2);

        // Creación de las escalas
        const xScale = d3.scaleTime()
            .domain([
                d3.min(historyData, d => d.time),
                d3.max(predictionData, d => d.time)
            ])
            .range([8, width - 46]);

        const yScale = d3.scaleLinear()
            .domain([
                d3.min([...historyData, ...predictionData, ...extraData], d => d.close) * 0.999,
                d3.max([...historyData, ...predictionData, ...extraData], d => d.close) * 1.001
            ])
            .range([height, 0]);

        // Creación de los ejes
        const xAxis = d3.axisBottom(xScale)
            .ticks(Math.max(3, width / 150))

        const yAxis = d3.axisRight(yScale)
            .ticks(Math.max(3, height / 50))
            .tickFormat(function (d) {
                return d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""); // Elimina las comas en números de miles
            });

        // Creación de la cuadrícula
        function makeGridLinesX() {
            return d3.axisBottom(xScale)
                .ticks(Math.max(3, width / 150))
        }

        // Añadir la cuadrícula horizontal
        svg.append("g")
            .attr("class", "grid-line")
            .attr("clip-path", "url(#clipX)") // Aplica el área de recorte a la cuadrícula
            .attr("transform", `translate(0, ${height_disp})`)
            .style("color", "var(--grid-color)") // color sutil de la cuadrícula
            .style("", "1.0")
            .call(makeGridLinesX()
                .tickSize(height) // Asegúrate de que el tamaño de la marca sea el ancho del gráfico
                .tickFormat("") // Sin texto en las marcas de la cuadrícula
            )

        function makeGridLinesY() {
            return d3.axisLeft(yScale)
                .ticks(Math.max(3, height / 50))
        }

        // Añadir la cuadrícula vertical
        svg.append("g")
            .attr("class", "grid-line")
            .attr("clip-path", "url(#clipY)") // Aplica el área de recorte a la cuadrícula
            .attr("transform", `translate(0, ${height_disp})`)
            .style("color", "var(--grid-color)") // color sutil de la cuadrícula
            .style("", "0.1")
            .call(makeGridLinesY()
                .tickSize(-width + 46) // Asegúrate de que el tamaño de la marca sea el ancho del gráfico
                .tickFormat("") // Sin texto en las marcas de la cuadrícula
            )

        // Renderización de los ejes
        svg.append("g")
            .attr("transform", `translate(0, ${height + height_disp})`)
            .attr("class", "axis")
            .call(xAxis)
            .attr("font-family", "Verdana")
            .attr("font-size", "10px")
            .attr("color", "var(--primary-color)")
            // Oculta la línea del eje X
            .call(g => g.select(".domain").remove())
            // Oculta las líneas de los ticks del eje X, si se desea
            .call(g => g.selectAll(".tick line").remove());

        svg.append("g")
            .attr("transform", `translate(${width - 50}, ${height_disp})`)
            .attr("class", "axis")
            .call(yAxis)
            .attr("font-family", "Verdana")
            .attr("font-size", "12px")
            .attr("color", "var(--primary-color)")
            // Oculta la línea del eje X
            .call(g => g.select(".domain").remove())
            // Oculta las líneas de los ticks del eje X, si se desea
            .call(g => g.selectAll(".tick line").remove());

        // Función para crear la gradiente
        const createGradient = (id, color) => {
            const gradient = svg.select('defs')
                .append('linearGradient')
                .attr('id', id)
                .attr('x1', '0%')
                .attr('x2', '0%')
                .attr('y1', '100%')
                .attr('y2', '0%');

            gradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', color) // Color del inicio del gradiente
                .attr('stop-opacity', 0.0);

            gradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', color) // Color del final del gradiente
                .attr('stop-opacity', 0.34);
        };

        // Crear un gradiente para cada conjunto de datos
        createGradient('history-gradient', 'var(--line-color)');
        createGradient('prediction-gradient', 'var(--secondary-color)');
        createGradient('extra-gradient', 'var(--text-color)');

        // Función para crear el área bajo la línea
        const createArea = (data, gradientId, translate = 0) => {
            const area = d3.area()
                .x(d => xScale(d.time))
                .y0(height) // Altura base del área (abajo)
                .y1(d => yScale(d.close)); // Altura del área (arriba)

            // Aplicar el área y el gradiente a los datos
            svg.append('path')
                .datum(data)
                .attr("transform", `translate(${translate}, ${height_disp})`)
                .attr('class', 'area')
                .attr('d', area)
                .attr('fill', `url(#${gradientId})`)
                .attr('stroke', 'none'); // Sin borde
        };

        // Crea las áreas para cada conjunto de datos
        createArea(historyData, 'history-gradient');
        createArea(predictionData, 'prediction-gradient');
        createArea(extraData, 'extra-gradient');

        // Función para crear la línea
        const createLine = (data, color, translate = 0) => {
            const line = d3.line()
                .x(d => xScale(d.time))
                .y(d => yScale(d.close));

            svg.append("path")
                .datum(data)
                .attr("transform", `translate(${translate}, ${height_disp})`)
                .attr("fill", "none")
                .attr("stroke", color)
                .attr("stroke-width", 1.4)
                .attr("d", line);

        };

        // Creación de las líneas para history y prediction con colores diferentes
        createLine(historyData, "var(--line-color)"); // Color para 'history'
        createLine(predictionData, "var(--secondary-color)"); // Color para 'prediction'
        createLine(extraData, "var(--text-color)"); // Color para 'prediction'

        // Añadir línea de predición
        svg.append("line")
            .attr("x1", xScale(predictionData[0]['time']))
            .attr("x2", xScale(predictionData[0]['time']))
            .attr("y1", height_disp)
            .attr("y2", height + height_disp)
            .attr("stroke", "var(--primary-color)")
            .attr("stroke-width", 1.4);

        svg.append("text")
            .attr("x", 112)
            .attr("y", 14)
            .attr("text-anchor", "middle")
            .style("font-size", "13px")
            .style("fill", "var(--hover-color)")
            .text(info["symbol"]);

        svg.append("text")
            .attr("x", 154)
            .attr("y", 14)
            .attr("text-anchor", "middle")
            .style("font-size", "13px")
            .style("fill", "var(--hover-color)")
            .text(info["interval"]);


    }, [results, historyData, predictionData, extraData, finalWidth, info, width, height]);

    const panelRef = useRef();  // Referencia al div que quieres capturar

    const copyDivToClipboard = () => {
        html2canvas(panelRef.current).then((canvas) => {
            canvas.toBlob(blob => {
                const item = new ClipboardItem({ "image/png": blob });
                navigator.clipboard.write([item]).then(() => {
                    console.log('Imagen copiada al portapapeles');
                }, (err) => {
                    console.error('Error al copiar imagen:', err);
                });
            });
        }).catch(err => console.error('Error al capturar el div:', err));
    };

    // Función para descargar el div como imagen
    const downloadDivAsImage = () => {
        html2canvas(panelRef.current).then((canvas) => {
            // Crear un elemento de enlace para descargar
            const link = document.createElement('a');
            link.download = 'grafico.png';
            link.href = canvas.toDataURL('image/png');
            link.click();
        }).catch(err => console.error('Error al capturar el div:', err));
    };

    return (
        <div className='panel-container'>
            <div className='panel-chart' ref={panelRef}>

                <svg className='graph-svg' ref={ref} width={width} height={height + 20 + height_disp} />
                <PairIcons pairName={info['symbol']}></PairIcons>
                <div className='app-logo'>
                    <AppLogo className='logo' />
                </div>
                <button
                    className={`material-symbols-outlined notranslate copy-button`}
                    onClick={copyDivToClipboard}
                >
                    Content_copy
                </button>
                <button
                    className={`material-symbols-outlined notranslate download-button`}
                    onClick={downloadDivAsImage}
                >
                    Download
                </button>

            </div>
        </div>
    );
};

export default LineChart;
