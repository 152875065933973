import './about.css'
import predictableBase from '../desings/project_fondo.jpg'
import graphics_a from '../desings/graphics_a.png'
import graphics_b from '../desings/graphics_b.png'

function About() {
    return (
        <div className='about'>
            <h1>About</h1>

            <h2>Our Mission</h2>
            <p>At Predictable, our mission is to make predictive analysis in the cryptocurrency market free and accessible to all. We're committed to empowering users to make informed strategic decisions by offering a powerful tool that is simultaneously easy to use and understand, regardless of experience level.</p>
            <img className='base-image' src={predictableBase} alt='predictable-base' />
            <h2>App</h2>
            <p>Predictable turns the complexity of market data into clear, accessible forecasts, allowing users to analyze cryptocurrency trends in real-time. Our platform is distinguished by its intuitive interface that maximizes efficiency and clarity, enabling traders and investors to easily visualize and compare different cryptocurrencies.</p>
            <div className='graphics-images'>
                <img className='graphics-a' src={graphics_a} alt='graphics_a' />
                <img className='graphics-b' src={graphics_b} alt='graphics_b' />
            </div>

            <h2>Innovative Technology</h2>
            <p>The technology powering Predictable is a set of micro neural networks, each trained on specific market scenarios through reinforced learning. This approach ensures that our users receive highly accurate forecasts, customized for the market situation, providing a unique competitive edge in cryptocurrency analysis.</p>

            <h2>Join the Community</h2>
            <p>Predictable is more than a platform; it's a community. We will soon introduce a token that will allow users to become an active part of our growth and success. This token will symbolize membership in Predictable and also offer members the chance to contribute to and share in the platform's achievements.</p>

            <h2>About the Founder</h2>
            <p>I am the creator of Predictable, a full-stack developer passionate about making cryptocurrency trading accessible to everyone. With extensive experience and knowledge in software development, I have dedicated countless hours to building Predictable to offer a tool that is both robust and reliable.</p>

            <h2>Investment in Predictable</h2>
            <p>Predictable is at an exciting stage of growth, and we're seeking investors who share our vision of innovation and accessibility in cryptocurrency analysis. We offer an opportunity to invest in a platform with significant market-disruptive potential, supporting a tool that's already making a difference in how people interact with cryptocurrencies.</p>

            <h2>Responsible Use</h2>
            <p>At Predictable, we emphasize the responsible use of our platform as part of a diversified investment strategy. Our services are designed to be a valuable indicator within your financial toolkit, complementing and enriching your decision-making process with advanced predictive information and analysis.</p>
        </div>
    )
}

export default About
