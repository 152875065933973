import React, { useState, useEffect } from 'react';
import './loadingChart.css';

const LoadingChart = () => {
  const [circleClasses, setCircleClasses] = useState(['loading-circle', 'loading-circle', 'loading-circle']);

  useEffect(() => {
    // Esta función actualiza la clase de cada círculo para controlar la fase
    const updateCircles = () => {
      setCircleClasses(prevClasses => 
        prevClasses.map((_, index) => `loading-circle blink-${index + 1}`)
      );
    };

    updateCircles();

    // Opcional: puedes usar un intervalo para cambiar la fase de manera dinámica
    // const interval = setInterval(updateCircles, 2000);

    // return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-chart">
        <div className="loading-animation">
          {circleClasses.map((className, index) => (
            <div key={index} className={className} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingChart;
