import './App.css';

import MainMenu from './components/mainMenu';
import Graphics from './components/graphics';
import Research from './components/research';
import Token from './components/model';
import Api from './components/api';
import About from './components/about';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <div className='Main-Bar'>
          <MainMenu />
        </div>
        <Routes>
          <Route path="/app" element={<Graphics />} />
          <Route path="/research" element={<Research />} />
          <Route path="/token" element={<Token />} />
          <Route path="/api" element={<Api />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Graphics />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
