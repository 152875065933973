import './mainMenu.css'
import { ReactComponent as AppLogo } from '../desings/predictable_logo_g.svg'
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


function MainMenu() {
    const [theme, setTheme] = useState('light');
    const toggleTheme = () => {
        
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    };
    useEffect(() => {
        const system_theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', system_theme);
        setTheme(system_theme);
    }, []);
    
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
    return (

        <div className='MainMenu'>
            <div className='Right-Group'>
                <AppLogo className='AppLogo' />

                <nav>
                    <ul>
                        <li>
                            <NavLink
                                to="/app"
                                className={({ isActive }) => isActive ? 'selected' : ''}
                            >
                                App
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/research"
                                className={({ isActive }) => isActive ? 'selected' : ''}
                            >
                                Research
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/token"
                                className={({ isActive }) => isActive ? 'selected' : ''}
                            >
                                Token
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/api"
                                className={({ isActive }) => isActive ? 'selected' : ''}
                            >
                                API
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" className={({ isActive }) => isActive ? 'selected' : ''}>About</NavLink>
                        </li>
                    </ul>
                </nav>

            </div>
            <button className="themeButton" onClick={toggleTheme}>
                {theme === 'light' ? (
                    <span className="material-symbols-outlined notranslate">
                        dark_mode
                    </span>
                ) : (
                    <span className="material-symbols-outlined notranslate">
                        light_mode
                    </span>
                )}
            </button>
        </div >




    );
}

export default MainMenu;