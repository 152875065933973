import bitcoinLogo from '../desings/bitcoin_128.png';
import ethereumLogo from '../desings/ethereum_128.png';
import tetherLogo from '../desings/tether_128.png';
import './pairIcons.css'

const iconList = {
    "BTCUSDT": [bitcoinLogo, tetherLogo],
    "ETHUSDT": [ethereumLogo, tetherLogo]
};

const PairIcons = ({ pairName }) => {
    // Verificar si el par existe en la lista para evitar errores
    const icons = iconList[pairName];

    if (!icons) {
        return <div>Par no encontrado</div>;
    }

    return (
        <div className='pair'>
            {icons.map((icon, index) => (
                <img className={'icon'}src={icon} alt={`${pairName}-icon-${index}`} key={index} />
            ))}
        </div>
    );
};

export default PairIcons;
